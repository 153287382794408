export enum Role {
  PLATFORM_MAINTAINER = 0,
  INVESTOR = 1,
  GENERAL_CONTRACTOR = 2,
  CONTRACTOR = 3,
}

type ConfigurationFields = {
  [key: string]: boolean; // this can be extended when necessary, at the moment, we only have 1 boolean field here
};

export interface UserLegalEntity {
  role: Role;
  id: number;
  name: string;
  street?: string;
  streetNumber?: string;
  postalCode?: string;
  city?: string;
  logo?: null | string;
  configuration: ConfigurationFields | null;
}

export interface LegalEntity {
  id: number;
  name: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
  vatId: string;

  billingStreet: string;
  billingStreetNumber: string;
  billingPostalCode: string;
  billingCity: string;
  billingCountry: string;

  phoneNumber: string;
  contactEmail: string;
  notificationEmail?: string;

  totalFileQuota: number;
  usedFileQuota: number;
  extraFileQuota: number;

  logo?: null | string;
}
